import {useParams} from "react-router-dom";
import React, {useEffect} from "react";
import {
    Alert, Divider,
    FormControl,
    Grid
} from "@mui/material";
import {getDetails, getS3PayloadList} from "../../service/ApiService";
import {DetailTable} from "./DetailTable";
import {ErrorGen2Table, ErrorGen2} from "./ErrorGen2Table";
import {TopMenu} from "./TopMenu";
import {PayloadSelector} from "./PayloadSelector";
import {useAuthenticator} from "@aws-amplify/ui-react";
import TextField from "@mui/material/TextField";
import {Result} from "../../types/Result";
import {fetchAuthSession} from "aws-amplify/auth";

export const Detail = () => {
    const {correlationId, target} = useParams();
    const {user} = useAuthenticator((context) => [
        context.user
    ]);

    const [selectedResult, setSelectedResult] = React.useState<Result>({
        start_date: 0,
        id: "",
        links: {datadog: [], docs: [], dynamodb: [], s3: [], step_function: []},
        source: "",
        target: "",
        type: "",
        status: "",
        with_ack: false,
        flow_context: {
            target: "",
        }
    })
    const [ackData, setAckData] = React.useState<Result>({
        start_date: 0,
        id: "",
        links: {datadog: [], docs: [], dynamodb: [], s3: [], step_function: []},
        source: "",
        target: "",
        type: "",
        status: "",
        with_ack: false,
        flow_context: {
            target: "",
        }
    })
    const [payloadList, setPayloadList] = React.useState([])

    useEffect(() => {
        async function fetchData() {
            const response = await getDetails(correlationId || "", target || "", (await fetchAuthSession()).tokens?.idToken?.toString() || "");
            setSelectedResult(response)

            if (response && response.with_ack && response.ack_id) {
                const ack = await getDetails(response.ack_id, "-", (await fetchAuthSession()).tokens?.idToken?.toString() || "")
                setAckData(ack)
            }

            const payloadResponse = await getS3PayloadList(correlationId || "", target || "", (await fetchAuthSession()).tokens?.idToken?.toString() || "")
            setPayloadList(payloadResponse)
        }

        fetchData()
    }, [correlationId, target, user]);

    let detailsComponent = <Alert severity="info">Details for the correlationId {correlationId} not found.</Alert>

    if (selectedResult) {
        let ackComponent = <Alert severity="info">ACK for the message with correlation ID {correlationId} not found.</Alert>

        if (ackData.id !== "") {
            ackComponent = <DetailTable id={ackData.id} status={ackData.status} start_date={ackData.start_date}
                                        type={ackData.type} source={ackData.source} target={ackData.flow_context.target} isAck={true} />
        }

        let errorComp: any = '';
        if (selectedResult.error) {
          const err = selectedResult.error;
          if (err.version !== 2) {
            const msg = err.message ? err.message : "No message provided";
            errorComp = <FormControl fullWidth>
            <TextField
                  id="textfield-error"
                  label="Error"
                  multiline
                  value={msg}
                  InputProps={{
                      readOnly: true,
                  }}
                  disabled={true}
              />
          </FormControl>;
          } else {
            errorComp = <ErrorGen2Table error={err as unknown as ErrorGen2}></ErrorGen2Table>;
          }
        }

        detailsComponent = <React.Fragment>
            <Grid className={"detailsContainer"} container spacing={4}>
                <Grid item xs={12}>
                    <TopMenu label={"Datadog"}
                             links={selectedResult.links?.datadog}/>
                    <TopMenu label={"DynamoDB"}
                             links={selectedResult.links?.dynamodb}/>
                    <TopMenu label={"Step Function"}
                             links={selectedResult.links?.step_function}/>
                    <TopMenu label={"S3"}
                             links={selectedResult.links?.s3}/>
                    <TopMenu label={"Docs"}
                             links={selectedResult.links?.docs}/>
                </Grid>
                <Grid xs={12}>
                    <h2>Details</h2>
                </Grid>
                <Grid item xs={6} width={500}>
                    <FormControl fullWidth>
                        <DetailTable id={selectedResult.id} status={selectedResult.status}
                                     type={selectedResult.type} source={selectedResult.source}
                                     target={selectedResult.target || selectedResult.flow_context.target} start_date={selectedResult.start_date}
                                     with_ack={selectedResult.with_ack ?? null} />
                    </FormControl>
                    {selectedResult.with_ack ? <FormControl fullWidth>
                        <h2>ACK</h2>
                        {ackComponent}
                    </FormControl> : ''}
                </Grid>
                <Grid item xs={6}>
                    <FormControl fullWidth>
                        <TextField
                            id="textfield-flowcontext"
                            label="Flow context"
                            multiline
                            value={JSON.stringify(selectedResult?.flow_context, null, 4) || ''}
                            InputProps={{
                                readOnly: true,
                            }}
                            disabled={true}
                        />
                    </FormControl>
                    {errorComp}
                </Grid>
            </Grid>
            <Divider orientation="horizontal" flexItem/>
            <h2>Payloads</h2>
            {payloadList ?
                <Grid className={"detailsContainer"} container spacing={4}>
                    <Grid item xs={6}>
                        <PayloadSelector correlationId={correlationId || ""} payloadList={payloadList}
                                         defaultSelectedFile={"raw"} target={target || ""} label={"left"}/>
                    </Grid>
                    <Grid item xs={6}>
                        <PayloadSelector correlationId={correlationId || ""} payloadList={payloadList}
                                         defaultSelectedFile={"published"} target={target || ""} label={"right"}/>
                    </Grid>
                </Grid> : ''}
        </React.Fragment>
    }

    return detailsComponent;
}