import React from "react";
import {
    Button,
    ButtonGroup,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Stack
} from "@mui/material";
import {Link} from "react-router-dom";
import TextField from "@mui/material/TextField";

export const DevTool = () => {
    const [chosenApp, setChosenApp] = React.useState("sapbest");

    const onChange = (event: SelectChangeEvent) => {
        setChosenApp(event.target.value);
    }

    return <React.Fragment>
        <Stack className={"App-header"} direction="row" spacing={2}>
            <ButtonGroup color={"primary"} aria-label="outlined primary button group">
                <Button variant={"outlined"}><Link style={{textDecoration: 'none', color: '#1976d2'}}
                                                   to={"/"}>Executions</Link></Button>
                <Button variant={"contained"}>Dev Tools</Button>
            </ButtonGroup>
        </Stack>
        <Grid container spacing={4} className={"detailsContainer"}>
            <Grid item xs={6}>
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Source Application</InputLabel>
                    <Select placeholder={"Choose a source application"} value={chosenApp} onChange={onChange}
                            labelId="demo-simple-select-label" label={"Source Application"}>
                        <MenuItem value={"sapbest"}>SAP BEST</MenuItem>
                        <MenuItem value={"sapnice"}>SAP NICE</MenuItem>
                        <MenuItem value={"MDP"}>MDP</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <TextField multiline rows={20}></TextField>
                </FormControl>
            </Grid>
            <Grid item xs={11}></Grid>
            <Grid item xs={1}><Button fullWidth variant={"contained"}>Run</Button></Grid>
        </Grid>
    </React.Fragment>
}