import { Paper, Table, TableBody, TableCell, TableContainer, TableRow, Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React from "react";

type Details = {
  id: string;
  type: string;
  description: string;
  message: string;
  area: string;
  component: string;
  component_arn: string;
  cause: string | Object;
  stack: string;
  extras: string | Object;
}

export type ErrorGen2 = {
  version: number;
  next_step: string;
  details: Details;
}

type ErrorGen2Props = {
  error: ErrorGen2;
}

const getDetailsForFlowOwner = (details: Details) => {
  const display = {
    extras: "",
    cause: "",
  };
  if (details.extras !== undefined) {
    if (details.extras instanceof Object) {
      display.extras += JSON.stringify(details.extras, null, 2);
    } else {
      display.extras += (details.extras ?? "No data was provided. If this is unexpected, get in touch with the HIP Main Contact Developer of the flow to enhance.").toString();
    }
  }
  if (details.cause) {
    if (details.cause instanceof Object) {
      display.cause += JSON.stringify(details.cause, null, 2);
    } else {
      display.cause += details.cause ?? "";
    }
  }
  return display;
}

export const ErrorGen2Table = (props: ErrorGen2Props) => {
  const err = props.error;
  const flowOwnerInfo = getDetailsForFlowOwner(err.details);
  return <React.Fragment>
    <h4>Error</h4>
    <TableContainer component={Paper}>
      <Table aria-label="Error Info">
        <TableBody>
          <TableRow>
            <TableCell component="th" scope="row">
              ID
            </TableCell>
            <TableCell>{err.details.id}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Description
            </TableCell>
            <TableCell>{err.details.description}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Message
            </TableCell>
            <TableCell>{err.details.message}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Location
            </TableCell>
            <TableCell>{err.details.area}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Type
            </TableCell>
            <TableCell>{err.details.type}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Next Step
            </TableCell>
            <TableCell>{err.next_step}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        Detailed information for the next contact
      </AccordionSummary>
      <AccordionDetails>
      <TableContainer component={Paper}>
          <Table aria-label="next-detailed-info">
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row">
                  Details
                </TableCell>
                <TableCell><pre>{flowOwnerInfo.extras}</pre></TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Cause
                </TableCell>
                <TableCell><pre>{flowOwnerInfo.cause}</pre></TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </AccordionDetails>
    </Accordion>
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        HIP advanced information
      </AccordionSummary>
      <AccordionDetails>
        <TableContainer component={Paper}>
          <Table aria-label="hip-advanced-info">
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row">
                  Component
                </TableCell>
                <TableCell>{err.details.component}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  ARN
                </TableCell>
                <TableCell>{err.details.component_arn}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Stacktrace
                </TableCell>
                <TableCell><pre>{err.details.stack}</pre></TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Cause
                </TableCell>
                <TableCell><pre>{flowOwnerInfo.cause}</pre></TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </AccordionDetails>
    </Accordion>
  </React.Fragment>
}