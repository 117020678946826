const baseUrlFirstPart = 'https://console.eda';
const baseUrlSecondPart = '.data.arkemacloud.com/api/v0/';

export function getApiBaseUrl() {
    const env = process.env.REACT_APP_ENV;
    switch (env) {
        case 'local':
            return "https://c0lo14rjnd.execute-api.eu-west-1.amazonaws.com/dev/api/v0/";
        case 'prod':
            return baseUrlFirstPart.concat(baseUrlSecondPart);
        case 'qual':
            return baseUrlFirstPart.concat('.qual', baseUrlSecondPart);
        case 'dev':
        default:
            return baseUrlFirstPart.concat('.dev', baseUrlSecondPart);
    }
}

export function getHeaderColor() {
    const env = process.env.REACT_APP_ENV;
    switch (env) {
        case 'prod':
            return "#4269ffe6";
        case 'qual':
            return "#45818ee6";
        case 'dev':
        default:
            return "#00933ee6";
    }
}

export function getHeaderTextColor() {
    const env = process.env.REACT_APP_ENV;
    switch (env) {
        case 'prod':
        case 'qual':
        case 'dev':
        default:
            return "#ffffff";
    }
}

export function getEnvLabel() {
    const env = process.env.REACT_APP_ENV;
    switch (env) {
        case 'prod':
            return "Prod";
        case 'qual':
            return "Qual";
        case 'dev':
        default:
            return "Dev";
    }
}