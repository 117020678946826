import {useRecoilState} from "recoil";
import {isResultsLoading, textSearch} from "../../../Atom";
import React from "react";
import TextField from "@mui/material/TextField";

export const TextSearch = () => {
    const [text, setTextSearch] = useRecoilState(textSearch);
    const [isLoadable,] = useRecoilState(isResultsLoading);

    const onChange = (event: any) => {
        setTextSearch(event.target.value);
    }

    return <TextField id={`text-search`} label="Text to search" variant="outlined" disabled={isLoadable} fullWidth={true} onChange={onChange} value={text} className={"search-menu-component"}/>
}