import {TextSearch} from "./TextSearch";
import {AppSelect} from "./AppSelect";
import {
    appFromState,
    appToState,
    dateFilterFrom,
    dateFilterTo,
    filteredResults,
    isResultsLoading,
    isTechnicalActive,
    selectedSearchType,
    selectedStatus,
    selectedType,
    textSearch
} from "../../../Atom";
import {TypeSelect} from "./TypeSelect";
import {ParamsSelect} from "./ParamsSelect";
import {DateFilter} from "./DateFilter";
import React, {useEffect} from "react";
import {Button} from "@mui/material";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import {useRecoilState} from "recoil";
import {fetchAuthSession} from "aws-amplify/auth";
import {getRecordsFromSearch} from "../../../service/ApiService";

export function SearchMenu() {
    const [filteredResult, setFilteredResults] = useRecoilState(filteredResults);
    const [appFrom,] = useRecoilState(appFromState);
    const [appTo,] = useRecoilState(appToState);
    const [type,] = useRecoilState(selectedType);
    const [dateFrom,] = useRecoilState(dateFilterFrom);
    const [dateTo,] = useRecoilState(dateFilterTo);
    const [text,] = useRecoilState(textSearch);
    const [status,] = useRecoilState(selectedStatus);
    const [searchType,] = useRecoilState(selectedSearchType);
    const [, setIsLoading] = useRecoilState(isResultsLoading);
    const [technicalActive, setTechnicalActive] = useRecoilState(isTechnicalActive);

    useEffect(() => {
        async function fetchData() {
            const authToken = (await fetchAuthSession()).tokens?.idToken?.toString();
            if (authToken) {
                const response = await getRecordsFromSearch({
                    from: appFrom || '',
                    to: appTo || '',
                    type: type || '',
                    startDate: dateFrom,
                    endDate: dateTo,
                    search: text,
                    searchtype: searchType,
                    status: status || '',
                    includeTechnical: technicalActive || false,
                    token: authToken});
                setFilteredResults(response);
            }
        }
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const runResearch = async () => {
        const searchParams = new URLSearchParams();

        if (appFrom) searchParams.set('from', appFrom);
        if (appTo) searchParams.set('to', appTo);
        if (type) searchParams.set('type', type);
        if (dateFrom) searchParams.set('startDate', dateFrom);
        if (dateTo) searchParams.set('endDate', dateTo);
        if (text) searchParams.set('search', text);
        if (status) searchParams.set('status', status);
        if (technicalActive) searchParams.set('withInternalMessages', 'true');

        const authToken = (await fetchAuthSession()).tokens?.idToken?.toString();
        if (authToken) {
            setIsLoading(true);
            setFilteredResults(await getRecordsFromSearch({
                from: appFrom || '',
                to: appTo || '',
                type: type || '',
                startDate: dateFrom,
                endDate: dateTo,
                search: text,
                searchtype: searchType,
                status: status || '',
                includeTechnical: technicalActive || false,
                token: authToken}));
            setIsLoading(false);
        }

        window.location.search = searchParams.toString();

        return filteredResult;
    }

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTechnicalActive(event.target.checked);
    };

    return <React.Fragment>
        <TextSearch/>
        <AppSelect label={"From"} stateToChange={appFromState}/>
        <AppSelect label={"To"} stateToChange={appToState}/>
        <TypeSelect stateToChange={selectedType} label={"Type"}/>
        <ParamsSelect label={"Status"} paramList={[
            {label: "Error", id: "error"},
            {label: "Warning", id: "warning"},
            {label: "Unprocessed", id: "unprocessed"},
            {label: "In progress", id: "inprogress"},
            {label: "Success", id: "success"}
        ]} stateToChange={selectedStatus}/>
        <DateFilter dateAtom={dateFilterFrom} dateLabel={"Date from"} hourLabel={"Hour from"} id={"date-from"}/>
        <DateFilter dateAtom={dateFilterTo} dateLabel={"Date to"} hourLabel={"Hour to"} id={"date-to"}/>
        <FormGroup style={{display: "none"}}>
            <FormControlLabel control={<Checkbox onChange={handleCheckboxChange} checked={technicalActive} />} label="Include technical messages" />
        </FormGroup>
        <Button variant="contained" onClick={runResearch}>Search</Button>
    </React.Fragment>;
}