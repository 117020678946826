import '../../../style/App.css';
import {Autocomplete} from '@mui/material';
import React, {useEffect, useState} from 'react';

import {RecoilState, useRecoilState,} from 'recoil';
import {getAppList} from "../../../service/ApiService";
import {useAuthenticator} from "@aws-amplify/ui-react";
import TextField from "@mui/material/TextField";
import {fetchAuthSession} from "aws-amplify/auth";
import {isResultsLoading} from "../../../Atom";

type AppSelectProps = {
    stateToChange: RecoilState<any>,
    label: string,
}

type AppElem = {
    id: string
}
export const AppSelect = (props: AppSelectProps) => {
    const [app, setApp] = useRecoilState(props.stateToChange);
    const [isLoading, ] = useRecoilState(isResultsLoading);
    const { user } = useAuthenticator((context) => [
        context.user
    ]);
    const [appList, setAppList] = useState<AppElem[]>([])

    useEffect(() => {
        async function fetchData() {
            const response = await getAppList((await fetchAuthSession()).tokens?.idToken?.toString() || "")
            setAppList(response)
        }
        fetchData()
    }, [user]);
  
    const onChange = (_event: React.SyntheticEvent, value: any, _reason: string) => {
      setApp(value ? value : '');
    }

    const appListDisplay = appList.filter((application) => application.id !== null).map((application) => application.id)

    return (
            <Autocomplete
                className={"select_app"}
                disablePortal
                disabled={isLoading}
                value={app}
                onChange={onChange}
                id={`select-${props.label}`}
                options={appListDisplay}
                renderInput={(params) => <TextField {...params} label={props.label} />}
            />
    );
  }