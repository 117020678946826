import {IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableRow} from "@mui/material";
import React from "react";
import dayjs from "dayjs";
import {useRecoilState} from "recoil";
import {selectedCorrelationId} from "../../Atom";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

type DetailTableProps = {
    start_date: number,
    id: string,
    status: string,
    type: string,
    source: string,
    target: string,
    with_ack?: boolean,
    isAck?: boolean,
}

export const DetailTable = (props: DetailTableProps) => {
    const [, setCorrelationId] = useRecoilState(selectedCorrelationId);

    const handleRowClick = (passedCorrelationId: string, passedTarget: string, isAck? : boolean) => {
        setCorrelationId(passedCorrelationId)
        if (!isAck) {
            return;
        }
        if (passedTarget) {
            window.open(`/${passedCorrelationId}/${passedTarget}`, "_blank");
        } else {
            window.open(`/${passedCorrelationId}`, "_blank");
        }
    }

    return <React.Fragment>
        <TableContainer component={Paper}>
            <Table aria-label="ACK">
                <TableBody>
                  <TableRow key="date">
                    <TableCell component="th" scope="row">
                      Date
                    </TableCell>
                    <TableCell align="right">{dayjs.unix(props.start_date).format('YYYY-MM-DD HH:mm')}</TableCell>
                  </TableRow>
                    <TableRow
                        key="correlation-id"
                    >
                        <TableCell component="th" scope="row">
                            Correlation ID
                        </TableCell>
                        <TableCell align="right" onClick={() => handleRowClick(props.id, props.target, props.isAck)}>
                            {props.id}
                            {props.isAck ? <IconButton aria-label={"Open in another tab"} style={{padding: "0px 8px 0px 8px"}}>
                                <OpenInNewIcon/>
                            </IconButton> : ""}
                        </TableCell>
                    </TableRow>
                    <TableRow
                        key="status"
                    >
                        <TableCell component="th" scope="row">
                            Status
                        </TableCell>
                        <TableCell align="right">{props.status}</TableCell>
                    </TableRow>
                    <TableRow
                        key="type"
                    >
                        <TableCell component="th" scope="row">
                            Type
                        </TableCell>
                        <TableCell align="right">{props.type}</TableCell>
                    </TableRow>
                    <TableRow
                        key="Source"
                    >
                        <TableCell component="th" scope="row">
                            Source
                        </TableCell>
                        <TableCell align="right">{props.source}</TableCell>
                    </TableRow>
                    <TableRow
                        key="Target"
                    >
                        <TableCell component="th" scope="row">
                            Target
                        </TableCell>
                        <TableCell align="right">{props.target}</TableCell>
                    </TableRow>
                    {props.isAck || props.with_ack === null ? '' :
                    <TableRow
                        key="with_ack"
                    >
                        <TableCell component="th" scope="row">
                            With ACK
                        </TableCell>
                        <TableCell align="right">{props.with_ack ? "Yes" : "No"}</TableCell>
                    </TableRow>}
                </TableBody>
            </Table>
        </TableContainer>
    </React.Fragment>
}