import React from "react";
import {Button, FormControl, Link, Menu, MenuItem} from "@mui/material";
import {HipLink} from "../../types/Result";

type TopMenuProps = {
    links: HipLink[],
    label: string,
}

export const TopMenu = (props: TopMenuProps) => {
    //Menu
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef<HTMLButtonElement>(null);
    const handleClick = () => {
        setOpen((prevOpen) => !prevOpen);
    };
    const handleClose = (event: Event | React.SyntheticEvent) => {
        if (
            anchorRef.current &&
            anchorRef.current.contains(event.target as HTMLElement)
        ) {
            return;
        }

        setOpen(false);
    };

    return <FormControl sx={{ m: 1 }}>
        <Button variant="contained"
                id="basic-button"
                ref={anchorRef}
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
        >
            {props.label}
        </Button>
        <Menu
            id="basic-menu"
            anchorEl={anchorRef.current}
            open={open}
            onClose={handleClose}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
        >
            {props.links.map(link =>
                <MenuItem onClick={handleClose}
                          key={link.name}
                          href={link.url}
                          component={Link}
                          target="_blank">{link.name}</MenuItem>
            )}
        </Menu>
    </FormControl>
}