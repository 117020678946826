import '../../../style/App.css';
import {Autocomplete} from '@mui/material';
import React, {useEffect, useState} from 'react';

import {useRecoilState,} from 'recoil';
import {getTypeList} from "../../../service/ApiService";
import {useAuthenticator} from "@aws-amplify/ui-react";
import TextField from "@mui/material/TextField";
import {fetchAuthSession} from "aws-amplify/auth";
import {isResultsLoading} from "../../../Atom";

type TypeSelectProps = {
    stateToChange: any,
    label: string,
}

type TypeElem = {
    id: string
}
export const TypeSelect = (props: TypeSelectProps) => {
    const [type, setType] = useRecoilState(props.stateToChange);
    const { user } = useAuthenticator((context) => [
        context.user
    ]);
    const [isLoading, ] = useRecoilState(isResultsLoading);
    const [typeList, setTypeList] = useState<TypeElem[]>([])

    useEffect(() => {
        async function fetchData() {
            const response = await getTypeList((await fetchAuthSession()).tokens?.idToken?.toString() || "")
            setTypeList(response)
        }
        fetchData()
    }, [user]);
  
    const onChange = (_event: React.SyntheticEvent, value: any, _reason: string) => {
      setType(value ? value : '');
    }

    const typeListDisplay = typeList.filter((type) => type.id !== null).map((type) => type.id)

    return (
        <Autocomplete
            className={"select_app"}
            disablePortal
            onChange={onChange}
            disabled={isLoading}
            value={type}
            id={`select-${props.label}`}
            options={typeListDisplay}
            renderInput={(params) => <TextField {...params} label={props.label} />}
        />
    );
  }