import '../../../style/App.css';

import {RecoilState, useRecoilState,} from 'recoil';
import {DatePicker, MobileTimePicker} from "@mui/x-date-pickers";
import dayjs from "dayjs";
import React from 'react';
import {isResultsLoading} from "../../../Atom";
import ClearIcon from '@mui/icons-material/Clear';
import {IconButton} from "@mui/material";

type DateFilterProps = {
    dateAtom: RecoilState<any>,
    dateLabel: string,
    hourLabel: string,
    id: string,

}

export const DateFilter = (props: DateFilterProps) => {
  const [actualDate, setDateFilter] = useRecoilState(props.dateAtom);
  const [isLoading,] = useRecoilState(isResultsLoading);

  const [date, setDate] = React.useState(actualDate ? dayjs.unix(actualDate) : null);
  const [hour, setHour] = React.useState(actualDate ? dayjs.unix(actualDate) : null);

  const onDateChangePicker = (value: any) => {
    setDate(value);

    let calculatedDate;
    if (hour) {
        calculatedDate = dayjs(value).hour(dayjs(hour).hour()).minute(dayjs(hour).minute());
    } else {
        calculatedDate = dayjs(value);
    }
      if (calculatedDate !== actualDate) {
          setDateFilter(dayjs(calculatedDate).unix());
      }
  }

    const onHourChangePicker = (value: any) => {
        setHour(value);

        let calculatedDate;
        if (date) {
            calculatedDate = dayjs(value).date(dayjs(date).date()).month(dayjs(date).month()).year(dayjs(date).year());
        } else {
            calculatedDate = dayjs(value);
        }
        if (calculatedDate !== actualDate) {
            setDateFilter(dayjs(calculatedDate).unix());
        }
    }

    const onClear = () => {
      setDate(null);
      setHour(null);
      setDateFilter("");
    }

  const datePicker = <DatePicker
    label={props.dateLabel}
    disabled={isLoading}
    value={!date ? null : dayjs(date)}
    className={"date-time-picker"}
    onAccept={onDateChangePicker}
  />

    const timePicker = <MobileTimePicker
        ampm={false}
        value={!hour ? null : dayjs(hour)}
        onAccept={onHourChangePicker}
        label={props.hourLabel}
        className={"date-time-picker"}
    />

    return <div className={"date-container"}>
        {datePicker}
        {timePicker}
        <IconButton onClick={onClear} style={{padding: 0, minWidth:0}}>
            <ClearIcon/>
        </IconButton>
    </div>;
}