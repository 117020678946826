import {useRecoilState} from "recoil";
import {
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow
} from "@mui/material";
import React from "react";
import dayjs from "dayjs";
import {filteredResults, isResultsLoading, selectedCorrelationId} from "../../Atom";
import Loader from "../Loader";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {Result} from "../../types/Result";

export const Results = () => {
    const [results,] = useRecoilState(filteredResults);
    const [, setCorrelationId] = useRecoilState(selectedCorrelationId);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(100);
    const [isLoading, ] = useRecoilState(isResultsLoading);

    const handleRowClick = (passedCorrelationId: string, passedTarget: string) => {
        setCorrelationId(passedCorrelationId)
        if (passedTarget) {
            window.open(`/${passedCorrelationId}/${passedTarget}`, "_blank");
        } else {
            window.open(`/${passedCorrelationId}`, "_blank");
        }
    }

    //Menu
    //When actions will be available, uncomment this part.
    /*const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };*/

    const handleChangePage = (_event: React.MouseEvent | null, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    if (isLoading) {
        return <Loader/>
    } else {
        return <Paper>
            <TableContainer component={Paper} style={{maxHeight: "100%"}}>
                <Table sx={{minWidth: 650}} aria-label="simple table" stickyHeader={true}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Date</TableCell>
                            <TableCell align="left">Correlation ID</TableCell>
                            <TableCell align="left">Type</TableCell>
                            <TableCell align="left">Source</TableCell>
                            <TableCell align="left">Target</TableCell>
                            <TableCell align="left">Status</TableCell>
                            <TableCell align="left"></TableCell>
                            {/*<TableCell align="left">Actions</TableCell>*/}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {results.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((result: Result) => (

                            <TableRow
                                key={`${result.id}-${result.target || result.flow_context.target}`}
                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                            >

                                <TableCell component="th" scope="row">
                                    {dayjs.unix(result.start_date).format('DD/MM/YYYY HH:mm')}
                                </TableCell>
                                <TableCell align="left">{result.id}</TableCell>
                                <TableCell align="left">{result.type}</TableCell>
                                <TableCell align="left">{result.source}</TableCell>
                                <TableCell align="left">{result.target || result.flow_context.target}</TableCell>
                                <TableCell align="left">{result.status}</TableCell>
                                <TableCell align="left" onClick={() => handleRowClick(result.id, result.target || result.flow_context.target)}>{
                                    <IconButton aria-label={"Open in another tab"}>
                                        <OpenInNewIcon/>
                                    </IconButton>}</TableCell>
                                {/* When dev actions will be available, uncomment this part. */}
                                {/*<TableCell align="left">
                            <IconButton id="long-button" onClick={handleClick}><MoreVert/></IconButton>
                            <Menu
                                id="long-menu"
                                MenuListProps={{
                                    'aria-labelledby': 'long-button',
                                }}
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                PaperProps={{
                                    style: {
                                        maxHeight: 48 * 4.5,
                                        width: '20ch',
                                    },
                                }}
                            >
                                <MenuItem key="send-ack" onClick={handleClose}>
                                    Send ACK
                                </MenuItem>
                                <MenuItem key="replay" onClick={handleClose}>
                                    Replay
                                </MenuItem>
                            </Menu>
                        </TableCell>*/}

                            </TableRow>

                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 50, 100]}
                colSpan={3}
                count={results.length}
                rowsPerPage={rowsPerPage}
                page={page}
                component="div"
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    }
}