import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from "./components/App";
import {Amplify} from "aws-amplify";
import {Authenticator} from "@aws-amplify/ui-react";
import {RecoilRoot} from "recoil";


const REACT_APP_IDENTITY_POOL_ID = process.env.REACT_APP_IDENTITY_POOL_ID ?? ""
const REACT_APP_USER_POOL_ID = process.env.REACT_APP_USER_POOL_ID ?? ""
const REACT_APP_USER_POOL_WEB_CLIENT_ID = process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID ?? ""
const REACT_APP_COGNITO_DOMAIN = process.env.REACT_APP_COGNITO_DOMAIN ?? ""


const redirectUrl = `${window.location.protocol}//${window.location.hostname}${(window.location.port) ? `:${window.location.port}` : ""}`;

Amplify.configure({
    Auth: {
        Cognito: {
            //  Amazon Cognito User Pool ID
            userPoolId: REACT_APP_USER_POOL_ID,
            // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
            userPoolClientId: REACT_APP_USER_POOL_WEB_CLIENT_ID,
            // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
            identityPoolId: REACT_APP_IDENTITY_POOL_ID,
            loginWith: {
                // OPTIONAL - Hosted UI configuration
                oauth: {
                    domain: (REACT_APP_COGNITO_DOMAIN || "").replace("https://", ""),
                    scopes: ["email", "profile", "openid"],
                    redirectSignIn: [redirectUrl],
                    redirectSignOut: [redirectUrl],
                    responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
                }
            }
        }
    }
})

const root = ReactDOM.createRoot(document.getElementById('root') || document.createElement('div'));
root.render(
    <React.StrictMode>
        <Authenticator.Provider>
            <RecoilRoot>
                <App/>
            </RecoilRoot>
        </Authenticator.Provider>
    </React.StrictMode>
);
