//App.js
import {BrowserRouter, Routes, Route} from 'react-router-dom';

import '../style/App.css';
import {Detail} from "./details/Detail";
import {DevTool} from "./devtool/DevTool";
import React from "react";
import {Home} from "./home/Home";
import {getEnvLabel, getHeaderColor, getHeaderTextColor} from "../utils";
import {
    Button
} from "@mui/material";
import {
    signInWithRedirect
} from 'aws-amplify/auth';
import {useAuthenticator, View} from "@aws-amplify/ui-react";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";

function MyRoutes() {
    const headerText = `HIP - EDA Console - ${getEnvLabel()}`

    //const [drawerOpen, setDrawerOpen] = React.useState(false);

    const {user} = useAuthenticator((context) => [
        context.user
    ]);

    const loginComp = <View className="auth-wrapper">
        <Button variant={"contained"} onClick={() => signInWithRedirect()}>Log in with SSO</Button>
    </View>

    /**const toggleDrawer =
        (anchor, open) =>
            (event) => {
                if (
                    event.type === 'keydown' &&
                    (event.key === 'Tab' ||
                        event.key === 'Shift')
                ) {
                    return;
                }
                setDrawerOpen(open);
            };

    const list = <Box
            sx={{width: 250}}
            role="presentation"
            onClick={toggleDrawer('left', false)}
            onKeyDown={toggleDrawer('left', false)}
        >
            <List>
                <ListItem key={'test'} disablePadding>
                    <ListItemButton>
                        <ListItemIcon>
                            <DehazeIcon/>
                        </ListItemIcon>
                        <ListItemText primary={'test'}/>
                    </ListItemButton>
                </ListItem>
            </List>
            <Divider/>
            <List>
                <ListItem key={'test'} disablePadding>
                    <ListItemButton>
                        <ListItemIcon>
                            <DehazeIcon/>
                        </ListItemIcon>
                        <ListItemText primary={'test'}/>
                    </ListItemButton>
                </ListItem>
            </List>
        </Box>;

    const drawerButton = <React.Fragment key={'left'}>
        <IconButton className={"drawer-menu"} aria-label={"Open menu"} onClick={toggleDrawer('left', true)}>
            <DehazeIcon/>
        </IconButton>
        <Drawer
            anchor={'left'}
            open={drawerOpen}
            onClose={toggleDrawer('left', false)}
        >
            {list}
        </Drawer>
    </React.Fragment>*/

    // use authenticator to check if user signed in.
    //If user signed in, then normal route.
    //If user not signed in, then redirect to oauth login page.

    return (
        <main>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <div className="App">
                    <header className="app-header" style={{backgroundColor: getHeaderColor()}}>
                        <p className={"app-name"} style={{color: getHeaderTextColor()}}>{headerText}</p>
                        <p className={"user-name"} style={{color: getHeaderTextColor()}}>{user?.username}</p>
                    </header>
                    {user ? <BrowserRouter>

                        <Routes>
                            <Route path="/" element={<Home/>}/>
                            <Route path="/:correlationId" element={<Detail/>}/>
                            <Route path="/:correlationId/:target" element={<Detail/>}/>
                            <Route path="/devTools" element={<DevTool/>}/>
                        </Routes>
                    </BrowserRouter> : loginComp}
                </div>
            </LocalizationProvider>
        </main>
    );
};

function App() {
    return <MyRoutes/>;
}

export default App;