import React from "react";
import {Stack} from "@mui/material";
import {Results} from "./ResultTable";
import Loader from "../Loader";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {SearchMenu} from "./searchMenu/SearchMenu";

export function Home() {

    return <React.Fragment>
        {/* When we'll have devTools, uncomment this part */}
        {/*<Stack className={"App-header"} direction="row" spacing={2}>
            <ButtonGroup color={"primary"} aria-label="outlined primary button group">
                <Button variant={"contained"}>Executions</Button>
                <Button variant={"outlined"}><Link style={{ textDecoration: 'none', color: '#1976d2' }} to={"/devTools"}>Dev Tools</Link></Button>
            </ButtonGroup>
        </Stack>*/}
        <div className={"app-container"}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Stack direction="column" spacing={2} className={"app-search-menu"}>
                    <SearchMenu/>
                </Stack>
            </LocalizationProvider>
            <React.Suspense fallback={<Loader/>}>
                <div className={"app-results"}>
                    <Results/>
                </div>
            </React.Suspense>
        </div>
    </React.Fragment>
}