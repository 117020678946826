import '../../../style/App.css';
import {Autocomplete} from '@mui/material';
import React from 'react';

import {RecoilState, useRecoilState,} from 'recoil';
import TextField from "@mui/material/TextField";
import {isResultsLoading} from "../../../Atom";

type ParamSelectProps = {
    stateToChange: RecoilState<any>,
    label: string,
    paramList: ParamElem[],
}

type ParamElem = {
    id: string,
    label: string,
}
export const ParamsSelect = (props: ParamSelectProps) => {
    const [param, setParam] = useRecoilState(props.stateToChange);
    const [isLoading, ] = useRecoilState(isResultsLoading);

    const onChange = (_event: React.SyntheticEvent, value: any) => {
        setParam(value ? value.id : '');
    }

    //const paramListDisplay = props.paramList.filter((param) => param.id !== null).map((param) => param.id)

    return (
        <Autocomplete
            className={"select_app"}
            disablePortal
            onChange={onChange}
            disabled={isLoading}
            id={`select-${props.label}`}
            options={props.paramList}
            value={param ? props.paramList.find((paramElem) => paramElem.id === param) : null}
            renderInput={(params) => <TextField {...params} label={props.label} />}
        />
    );
}