import {
    FormControl,
    MenuItem,
    Select, SelectChangeEvent
} from "@mui/material";
import React, {useEffect} from "react";
import {getS3Payload} from "../../service/ApiService";
import {useAuthenticator} from "@aws-amplify/ui-react";
import {PayloadDetail} from "../../types/Result";
import CodeMirror from '@uiw/react-codemirror';
import {json} from '@codemirror/lang-json'
import {fetchAuthSession} from "aws-amplify/auth";

type PayloadSelectorProps = {
    payloadList: PayloadDetail[],
    defaultSelectedFile: string,
    correlationId: string,
    target: string,
    label: string
}

export const PayloadSelector = (props: PayloadSelectorProps) => {
    const [selectedFile, setSelectedFile] = React.useState(props.defaultSelectedFile);
    const [selectedFilePayload, setSelectedFilePayload] = React.useState('');
    const {user} = useAuthenticator((context) => [
        context.user
    ]);

    useEffect(() => {
        async function fetchData() {
            const response = await getS3Payload(props.correlationId, props.target, selectedFile, (await fetchAuthSession()).tokens?.idToken?.toString() || "");
            setSelectedFilePayload(response)
        }

        fetchData().catch((e: any) => {
            console.log(e);
            //Handle for error later.
        })
    }, [props.correlationId, selectedFile, user, props.target]);

    const onChange = async (event: SelectChangeEvent) => {
        setSelectedFile(event.target.value);

        async function fetchData() {
            const response = await getS3Payload(props.correlationId, props.target, event.target.value, (await fetchAuthSession()).tokens?.idToken?.toString() || "")
            setSelectedFilePayload(response)
        }

        await fetchData()
    }
    const extensions = [json()]
    return <FormControl fullWidth>
        <FormControl sx={{m: 1}}>
            <Select autoWidth={true} id={`selectpayload-${props.label}`} className={"select_payload"}
                    placeholder={"Choose an available payload."} onChange={onChange}
                    value={props.payloadList.map((payloadDetail) => payloadDetail.id).includes(selectedFile) ? selectedFile : ''}>
                {props.payloadList.map((payload: PayloadDetail) => {
                    return <MenuItem value={payload.id} key={`left-${payload.id}`}>{payload.name}</MenuItem>
                })}
            </Select>
        </FormControl>
        <CodeMirror value={JSON.stringify(selectedFilePayload, null, 2)} height="auto" extensions={extensions}
                    id={`textfield-${props.label}`} readOnly={true} theme={"light"}
                    style={{border: "1px solid #bbb", textAlign: "left"}}/>
    </FormControl>
}