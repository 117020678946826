import {atom} from "recoil";

const searchParams = new URLSearchParams(window.location.search);

export const appFromState = atom({
    key: 'appFromState',
    default: searchParams.get('from') || null,
});

export const appToState = atom({
    key: 'appToState',
    default: searchParams.get('to') || null,
});

export const dateFilterFrom = atom({
    key: 'dateFilterFrom',
    default: searchParams.get('startDate') || '',
})

export const dateFilterTo = atom({
    key: 'dateFilterTo',
    default: searchParams.get('endDate') || '',
});

export const textSearch = atom({
    key: 'textSearch',
    default: searchParams.get('search') || '',
})

export const selectedCorrelationId = atom({
    key: 'selectedCorrelationId',
    default: '',
})

export const selectedType = atom({
    key: 'selectedType',
    default: searchParams.get('type') || null,
})

export const selectedSearchType = atom({
    key: 'selectedSearchType',
    default: 'equals'
})

export const selectedStatus = atom({
    key: 'selectedStatus',
    default: searchParams.get('status') || null,
})

export const isResultsLoading = atom({
    key: 'isResultsLoading',
    default: false
});

export const filteredResults = atom({
    key: 'filteredResults',
    default: []
})

export const isTechnicalActive = atom({
    key: 'isTechnicalActive',
    default: searchParams.get("withInternalMessages") === 'true' || false,
});