import axios from "axios";
import {getApiBaseUrl} from "../utils";
import {GetRecordsParams} from "./types";

const baseUrl = getApiBaseUrl();

export function getFlowId(correlationId: string, target: string) {
  if (!target) {
    return `${correlationId}/-`;
  }

  return `${correlationId}/${target}`;
}

export async function getAppList(token: string) {
    return axios({
        method: 'get',
        url:`${baseUrl}app`,
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
        }
    }).then(result => {
        return result.data.results
    });
}

export async function getTypeList(token: string) {
    return axios({
        method: 'get',
        url:`${baseUrl}type`,
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
        }
    }).then(result => {
        return result.data.results
    });
}

export async function getRecordsFromSearch(params: GetRecordsParams) {
    return axios({
        method: 'get',
        url:`${baseUrl}execution?from=${params.from}&to=${params.to}&type=${params.type}&startDate=${params.startDate}&endDate=${params.endDate}&search=${params.search}&searchtype=${params.searchtype}&status=${params.status}&withInternalMessages=${params.includeTechnical}`,
        headers: {
            'Authorization': `Bearer ${params.token}`,
            'Content-Type': 'application/json',
        }
    }).then(result => {
        return result.data.results
    })
}

export async function getDetails(correlationId: string, target: string, token: string) {
    return axios({
        method: 'get',
        url:`${baseUrl}execution/${getFlowId(correlationId, target)}`,
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
        }
    }).then(result => {
        return result.data.result
    })
}

export async function getS3PayloadList(correlationId: string, target: string, token: string) {
    return axios({
        method: 'get',
        url:`${baseUrl}execution/${getFlowId(correlationId, target)}/payload`,
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
        }
    }).then(result => {
        return result.data.results
    })
}

export async function getS3Payload(correlationId: string, target: string, payloadId: string, token: string) {
    return axios({
        method: 'get',
        url:`${baseUrl}execution/${getFlowId(correlationId, target)}/payload/${payloadId}`,
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
        }
    }).then(result => {
        return result.data.result
    })
}
